import Highway from '@dogstudio/highway'
import loadComponents from '../loadComponents'

class DefaultRenderer extends Highway.Renderer {
  onEnter() {
    loadComponents(this.wrap.lastElementChild)
  }
}

export default DefaultRenderer
