import Highway from '@dogstudio/highway'
import { gsap } from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'

gsap.registerPlugin(ScrollToPlugin)

class BackgroundTransition extends Highway.Transition {
  in({ from, to, trigger, done }) {
    const background = document.querySelector('[data-section="background"]')
    const backgroundVariant = to.getAttribute('data-background')
    const tl = gsap.timeline({ defaults: { ease: 'none' }, paused: true })

    window.requestAnimationFrame(() => {
      from.remove()
      background.setAttribute('data-variant', backgroundVariant)
      window.scrollTo(0, 0)
    })

    if (to.classList.contains('main__wrapper--dark')) {
      gsap.to(background, {
        backgroundColor: 'rgb(0, 0, 0)',
        duration: 0.4,
      })
    } else {
      gsap.to(background, {
        backgroundColor: 'rgb(255, 255, 255)',
        duration: 0.4,
      })
    }

    const animProps = { blur: 0 }

    tl.add('backgroundStart')
      .fromTo(
        background.firstElementChild,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          duration: 0.1,
        },
      )
      .add('backgroundBlur')
      .fromTo(
        animProps,
        {
          blur: 0,
        },
        {
          blur: 1,
          duration: 5,
          onUpdate: () => {
            gsap.set(background.firstElementChild, {
              webkitFilter: `blur(${animProps.blur}vw)`,
              filter: `blur(${animProps.blur}vw)`,
            })
          },
        },
      )
      .to(
        background.firstElementChild,
        {
          opacity: 0.3,
          scale: 0.9,
          duration: 5,
        },
        'backgroundBlur',
      )
      .add('contentStart')
      .fromTo(
        to,
        {
          y: 50,
          opacity: 0,
        },
        {
          y: 0,
          opacity: 1,
          duration: 3,
        },
        'contentStart-=1.5',
      )

    gsap.to(tl, {
      progress: 1,
      duration: 3,
      ease: 'expo.inOut',
      onStart: () => {
        window.requestAnimationFrame(() => {
          document.body.classList.remove('is-loading')
        })

        gsap.delayedCall(2, () => {
          if (window.location.hash) {
            gsap.to(window, {
              duration: 1,
              ease: 'power2.inOut',
              scrollTo: {
                y: window.location.hash,
                offsetY: 63,
              },
            })
          }
        })

        done()
      },
      onComplete: () => {
        gsap.set(to, {
          clearProps: 'transform',
        })
      },
    })
  }

  out({ from, trigger, done }) {
    const background = document.querySelector('[data-section="background"]')

    gsap.to([background.firstElementChild, from], {
      opacity: 0,
      duration: 0.4,
      onStart: () => {
        document.body.classList.add('is-loading')
        from.style.pointerEvents = 'none'
      },
      onComplete: done,
    })
  }
}

export default BackgroundTransition
