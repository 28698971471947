import 'lazysizes'
import { gsap } from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import Navigation from './global/navigation'
import Router from './global/router'

gsap.registerPlugin(ScrollToPlugin)

document.addEventListener('lazybeforeunveil', (e) => {
  window.requestAnimationFrame(() => {
    e.target.parentElement.nextElementSibling.classList.add('loaded')
  })
})

class Page {
  constructor() {
    this.header = undefined
    this.headerItems = undefined
    this.navigation = undefined
    this.router = undefined
    this.timeline = undefined
    this.headerEl = document.querySelector('[data-section="header"]')
    this.headerItems = this.headerEl.querySelectorAll('[data-item]')
    this.backgroundEl = document.querySelector('[data-section="background"]')
    this.content = document.querySelector('[data-router-view]')
    this.backgroundVariant = this.content.getAttribute('data-background')

    this.init()
  }

  setViewHeight() {
    return new Promise((resolve) => {
      window.requestAnimationFrame(() => {
        document.documentElement.style.setProperty(
          '--vh',
          `${window.innerHeight / 100}px`,
        )

        resolve()
      })
    })
  }

  initNavigation() {
    this.navigation = new Navigation(
      document.querySelector('[data-component="navigation"]'),
      document.querySelector('[data-component="toggle"]'),
    )
  }

  initRouter() {
    this.router = new Router()

    this.router.on('NAVIGATE_OUT', ({ location }) => {
      this.navigation.close()
      this.navigation.update(location.href)

      this.headerItems.forEach((item) => {
        if (item.href === location.href) {
          window.requestAnimationFrame(() => {
            item.classList.add('is-active')
          })
        } else {
          window.requestAnimationFrame(() => {
            item.classList.remove('is-active')
          })
        }
      })

      window.dispatchEvent(
        new CustomEvent('NAVIGATE_OUT', { detail: location.pathname }),
      )
    })

    window.addEventListener('ATTACH_LINKS', (event) => {
      this.router.attach(event.detail)
    })
  }

  attachListeners() {
    window.addEventListener('resize', this.setViewHeight.bind(this))

    document.addEventListener('lazybeforeunveil', (e) => {
      window.requestAnimationFrame(() => {
        e.target.parentElement.nextElementSibling.classList.add('loaded')
      })
    })
  }

  createTransition() {
    return new Promise((resolve) => {
      window.requestAnimationFrame(() => {
        this.backgroundEl.setAttribute('data-variant', this.backgroundVariant)
      })

      if (this.content.classList.contains('main__wrapper--dark')) {
        gsap.to(this.backgroundEl, {
          backgroundColor: 'rgb(0, 0, 0)',
          duration: 0.4,
        })
      } else {
        gsap.to(this.backgroundEl, {
          backgroundColor: 'rgb(255, 255, 255)',
          duration: 0.4,
        })
      }

      this.timeline = gsap.timeline({
        defaults: {
          ease: 'none',
        },
        paused: true,
      })

      const animProps = { blur: 0 }

      this.timeline
        .add('blurStart')
        .to(this.backgroundEl.firstElementChild, {
          opacity: 0.3,
          scale: 0.9,
          duration: 0.6,
        })
        .to(
          animProps,
          {
            blur: 1,
            duration: 0.4,
            onUpdate: () => {
              gsap.set(this.backgroundEl.firstElementChild, {
                webkitFilter: `blur(${animProps.blur}vw)`,
                filter: `blur(${animProps.blur}vw)`,
              })
            },
          },
          'blurStart',
        )

      this.timeline
        .add('contentStart')
        .to(this.headerEl, {
          opacity: 1,
          duration: 0.6,
        })
        .fromTo(
          this.content,
          {
            y: 50,
            opacity: 0,
          },
          {
            y: 0,
            opacity: 1,
            duration: 1,
          },
          'contentStart+=0.4',
        )

      resolve()
    })
  }

  transitionIn() {
    gsap.to(this.timeline, {
      progress: 1,
      duration: 3,
      delay: 0.5,
      ease: 'expo.inOut',
      onStart: () => {
        gsap.delayedCall(2, () => {
          if (window.location.hash) {
            gsap.to(window, {
              duration: 1,
              ease: 'power2.inOut',
              scrollTo: {
                y: window.location.hash,
                offsetY: 63,
              },
            })
          }
        })

        window.requestAnimationFrame(() => {
          document.body.classList.remove('is-loading')
        })
      },
      onComplete: () => {
        gsap.set(this.content, {
          clearProps: 'transform',
        })
      },
    })
  }

  async init() {
    await this.setViewHeight()
    await this.createTransition()

    this.initNavigation()
    this.initRouter()
    this.attachListeners()
    this.transitionIn()
  }
}

window.addEventListener('load', () => {
  new Page()
})
