const loadComponents = (root) => {
  const masonryGrids = root.querySelectorAll('[data-masonry]')
  const hero = root.querySelector('[data-component="hero"]')
  const videos = root.querySelectorAll('[data-component="video"]')
  const journal = root.querySelector('[data-component="journal"]')
  const videoPlayers = root.querySelectorAll('[data-component="video-player"]')
  const artistGalleries = root.querySelectorAll('[data-component="carousel"]')
  const exhibitorsSliders = root.querySelectorAll('[data-component="slider"]')
  const exhibitorsList = root.querySelector('[data-component="list"]')
  const programme = root.querySelector('[data-component="programme"]')
  const sponsors = root.querySelector('[data-component="sponsors"]')

  if (
    masonryGrids.length > 0 &&
    window.getComputedStyle(masonryGrids[0]).gridTemplateRows !== 'masonry'
  ) {
    import(/* webpackChunkName: "masonry" */ '../components/masonry').then(
      ({ default: Masonry }) => {
        Array.from(masonryGrids).forEach((grid) => {
          new Masonry(grid)
        })
      },
    )
  }

  if (hero !== null) {
    import(/* webpackChunkName: "hero" */ '../components/hero').then(
      ({ default: Hero }) => {
        new Hero(hero)
      },
    )
  }

  if (videos.length > 0) {
    import(/* webpackChunkName: "video" */ '../components/video').then(
      ({ default: Video }) => {
        Array.from(videos).forEach((video) => {
          new Video(video)
        })
      },
    )
  }

  if (journal !== null) {
    import(/* webpackChunkName: "journal" */ '../components/journal').then(
      ({ default: Journal }) => {
        new Journal(journal)
      },
    )
  }

  if (videoPlayers.length > 0) {
    import(
      /* webpackChunkName: "videoplayer" */ '../components/videoPlayer'
    ).then(({ default: VideoPlayer }) => {
      Array.from(videoPlayers).forEach((el) => {
        new VideoPlayer(el)
      })
    })
  }

  if (artistGalleries.length > 0) {
    import(
      /* webpackChunkName: "artistgallery" */ '../components/artistGallery'
    ).then(({ default: ArtistGallery }) => {
      Array.from(artistGalleries).forEach((el) => {
        new ArtistGallery(el)
      })
    })

    import(
      /* webpackChunkName: "artistlightbox" */ '../components/artistLightbox'
    ).then(({ default: ArtistLightbox }) => {
      Array.from(artistGalleries).forEach((el) => {
        new ArtistLightbox(el)
      })
    })
  }

  if (exhibitorsSliders.length > 0) {
    import(
      /* webpackChunkName: "exhibitorsslider" */ '../components/exhibitorsSlider'
    ).then(({ default: ExhibitorsSlider }) => {
      Array.from(exhibitorsSliders).forEach((el) => {
        new ExhibitorsSlider(el)
      })
    })
  }

  if (exhibitorsList !== null) {
    import(/* webpackChunkName: "list" */ '../components/exhibitorsList').then(
      ({ default: ExhibitorsList }) => {
        new ExhibitorsList(exhibitorsList)
      },
    )
  }

  if (programme !== null) {
    import(/* webpackChunkName: "programme" */ '../components/programme').then(
      ({ default: Programme }) => {
        new Programme(programme)
      },
    )
  }

  if (sponsors !== null) {
    import(/* webpackChunkName: "sponsors" */ '../components/sponsors').then(
      ({ default: Sponsors }) => {
        new Sponsors(sponsors)
      },
    )
  }

  if (window.location.pathname === '/privacy-policy') {
    import(
      /* webpackChunkName: "cookiebot" */ '../components/loadCookiebot'
    ).then(({ default: loadCookiebot }) => {
      loadCookiebot(root)
    })
  }
}

export default loadComponents
