import { gsap } from 'gsap'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

class Navigation {
  constructor(element, trigger) {
    this.el = element
    this.openBtn = trigger

    this.items = this.el.querySelectorAll('[data-item]')
    this.closeBtn = this.el.querySelector('[data-close]')
    this.overlay = this.el.querySelector('[data-overlay]')
    this.content = this.el.querySelector('[data-content]')
    this.primaryNav = this.content.querySelector('[data-primary]')
    this.secondaryNav = this.content.querySelector('[data-secondary]')
    this.footer = this.content.querySelector('[data-footer]')

    this.isOpen = false

    this.timelineIn = undefined
    this.timelineOut = undefined

    this.setup()
  }

  createTimelines() {
    this.timelineIn = gsap
      .timeline({ paused: true })
      .set(this.el, { opacity: 1 })
      .add('start')
      .set(this.content, { xPercent: 100 })
      .add('start')
      .to(this.content, {
        xPercent: 0,
        ease: 'circ.inOut',
        duration: 0.6,
      })
      .fromTo(
        [this.primaryNav, this.secondaryNav],
        {
          x: 24,
          opacity: 0,
        },
        {
          x: 0,
          opacity: 1,
          ease: 'expo.out',
          duration: 0.6,
          stagger: {
            amount: 0.1,
            from: 'start',
          },
        },
        '-=0.2',
      )
      .to(
        this.overlay,
        {
          opacity: 1,
          duration: 0.6,
          ease: 'expo.inOut',
        },
        'start+=0.2',
      )
      .fromTo(
        this.footer,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          duration: 0.6,
          ease: 'circ.inOut',
        },
        'start',
      )
      .fromTo(
        this.closeBtn,
        {
          scale: 0.5,
          opacity: 0,
        },
        {
          scale: 1,
          opacity: 1,
          ease: 'circ.inOut',
          duration: 0.4,
        },
        '-=0.6',
      )
      .eventCallback('onStart', () => {
        this.el.style.pointerEvents = 'auto'
      })

    this.timelineOut = gsap
      .timeline({ paused: true })
      .add('start')
      .to(this.closeBtn, {
        scale: 0.5,
        opacity: 0,
        ease: 'circ.inOut',
        duration: 0.25,
      })
      .to(
        [this.primaryNav, this.secondaryNav],
        {
          x: 24,
          opacity: 0,
          ease: 'expo.out',
          duration: 0.4,
          stagger: {
            amount: 0.1,
            from: 'end',
          },
        },
        'start',
      )
      .to(
        this.content,
        {
          xPercent: 100,
          ease: 'circ.inOut',
          duration: 0.6,
        },
        'start+=0.15',
      )
      .to(
        this.footer,
        {
          opacity: 0,
          duration: 0.4,
          ease: 'circ.inOut',
        },
        'start',
      )
      .to(
        this.overlay,
        {
          opacity: 0,
          duration: 0.5,
          ease: 'expo.inOut',
        },
        'start+=0.1',
      )
      .eventCallback('onStart', () => {
        this.el.style.pointerEvents = 'none'
      })
  }

  open() {
    this.isOpen = true
    this.timelineIn.seek(0).play()
    disableBodyScroll(this.el)
  }

  close() {
    if (this.isOpen) {
      this.isOpen = false
      this.timelineOut.seek(0).play()
      enableBodyScroll(this.el)
    }
  }

  update(location) {
    this.items.forEach((item) => {
      if (item.href === location) {
        window.requestAnimationFrame(() => {
          item.classList.add('is-active')
        })
      } else {
        window.requestAnimationFrame(() => {
          item.classList.remove('is-active')
        })
      }
    })
  }

  setup() {
    this.openBtn.addEventListener('click', this.open.bind(this), false)
    this.closeBtn.addEventListener('click', this.close.bind(this), false)
    this.overlay.addEventListener('click', this.close.bind(this), false)

    this.createTimelines()
  }
}

export default Navigation
